window.axios = require('axios');
//window.fuse = require('fuse.js');
window.Vue = require('vue');
window.$ = window.jQuery = require('jquery');
window.AOS = require('AOS');

import 'slick-carousel';

import hljs from 'highlight.js';
// Syntax highlighting
hljs.registerLanguage('bash', require('highlight.js/lib/languages/bash'));
hljs.registerLanguage('css', require('highlight.js/lib/languages/css'));
hljs.registerLanguage('html', require('highlight.js/lib/languages/xml'));
hljs.registerLanguage('javascript', require('highlight.js/lib/languages/javascript'));
hljs.registerLanguage('json', require('highlight.js/lib/languages/json'));
hljs.registerLanguage('markdown', require('highlight.js/lib/languages/markdown'));
hljs.registerLanguage('php', require('highlight.js/lib/languages/php'));
hljs.registerLanguage('scss', require('highlight.js/lib/languages/scss'));
hljs.registerLanguage('yaml', require('highlight.js/lib/languages/yaml'));

document.querySelectorAll('pre code').forEach((block) => {
    hljs.highlightBlock(block);
});

AOS.init();

$("nav .more-nav").mouseenter(function() {
    $('.more-list').show();
}).mouseleave(function() {
    $('.more-list').hide();
});